<template>
    <div style="height: 100%; width: 100%; overflow: hidden;">
        <div class="exam-answer" style="height: 100%; width: 100%;">
            <div class="mockhead">
                <div v-if="!isDown" class="mockinfo">
                    <div class="userinfo">
                        <img src="../../../assets/img/avatar.png" alt="" class="userhead">
                        <div class="other">
                            <p class="username">姓名：柏杜学员610895241</p>
                            <p class="username">姓别：X</p>
                            <p class="username">准考证号：62453363</p>
                            <p class="userID">身份证号：12330***6346895241</p></div>
                    </div>
                    <div class="paperinfo"><h6 class="title1">国家统一法律职业资格考试计算机化考试模拟答题演示</h6>
                        <h6 class="title2">{{ subjectivePaper.bdSubjectivePaper.name }}</h6></div>
                    <div class="submit" style=""><b class="time">考试剩余时间：{{ toLiveBtn }}</b>
                        <button type="button" class="button submit-btn" @click="handleJudge">交卷</button>
                    </div>
                </div>
                <div v-if="!isDown" class="hidetop" @click="toDown"></div>
                <div v-if="isDown" class="hidetop down" @click="toDown"></div>
            </div>
            <div class="center" :style="{background: bgColor, height: isDown ? '100vh' : 'calc(100vh - 100px)'}">
                <div class="leftnav" :class="{show: leftShow}">
                    <div class="topic-card">
                        <div class="subjcard">
                            <p class="card-top" :class="{active: isShow1}" @click="isShow1 = !isShow1">一、论述题</p>
                            <div class="subitem" v-show="isShow1">
                                <a class="a" v-for="(question, idx) in subjectivePaper.subjectiveList1"
                                   :key="idx" @click="updateCurrentQuestion(question, idx, 0)"
                                   :class="{active: (tempSubjectiveKey === idx) && (questionType === 0)}"
                                   :style="{color: question.isComplete ? 'green' : ''}">
                                    {{ idx + 1 }} {{ question.isComplete ? '' : '*' }}
                                </a>
                            </div>
                        </div>
                        <div class="subjcard">
                            <p class="card-top" :class="{active: isShow2}" @click="isShow1 = !isShow2">二、案例分析题</p>
                            <div class="subitem" v-show="isShow2">
                                <a class="a" v-for="(question, idx) in subjectivePaper.subjectiveList2"
                                   :key="idx" @click="updateCurrentQuestion(question, idx, 1)"
                                   :class="{active: (tempSubjectiveKey === idx) && (questionType === 1)}"
                                   :style="{color: question.isComplete ? 'green' : ''}">
                                    {{ subjectivePaper.subjectiveList1.length + idx + 1 }}
                                    {{ question.isComplete ? '' : '*' }}
                                </a>
                            </div>
                        </div>
                        <div class="submark"><p>说明：</p>
                            <p>1.题号右方的“*”符号代表该题尚未作答。</p>
                            <p>2.题号右方的“?”符号代表该题做过标记。</p>
                            <p>3.作答的同时系统自动保存答案。</p>
                            <p>4.主观题考试设置选做题的，应试员可选择其一作答</p></div>
                        <div class="fagui" @click="lawShow = true">法律法规汇编</div>
                    </div>
                    <div class="options-bar">
                        <label>
                            <input id="testflag" name="testflag" type="checkbox" class="testflag">标记
                        </label>
                        <button type="button" class="button" :disabled="isLastEnd" @click="nextQuestion(false)">上一问
                        </button>
                        <div class="topicnub">
                            第
                            {{
                                questionType === 0 ? (tempSubjectiveKey + 1) : (subjectivePaper.subjectiveList1.length + tempSubjectiveKey + 1)
                            }}
                            题
                        </div>
                        <button type="button" class="button" :disabled="isNextEnd" @click="nextQuestion(true)">下一问
                        </button>
                    </div>
                </div>
                <div class="content">
                    <div class="pack-up">
                        <div class="pack-up-btn" @click="leftShow = !leftShow" :class="{open: !leftShow}"></div>
                    </div>
                    <div class="paperTitle">
                        <div class="li single">试卷</div> <!---->
                        <div class="li themeLi">
                            <button class="button themeP" @click="isStyle = !isStyle; isFontSet = false">风格</button>
                            <button class="button themeP" @click="fontSet">字体</button>
                            <div class="setStyle" v-if="isStyle">
                                <div class="theme theme1" @click="bgColor = 'rgb(221, 235, 246)'"
                                     style="background-color: rgb(221, 235, 246)">风格一
                                </div>
                                <div class="theme theme2" @click="bgColor = 'rgb(202, 224, 224)'"
                                     style="background-color: rgb(202, 224, 224)">风格二
                                </div>
                                <div class="theme theme3" @click="bgColor = 'rgb(245, 233, 220)'"
                                     style="background-color: rgb(245, 233, 220)">风格三
                                </div>
                                <div class="theme theme4" @click="bgColor = 'rgb(245, 220, 228)'"
                                     style="background-color: rgb(245, 220, 228)">风格四
                                </div>
                                <div class="theme theme5" @click="bgColor = 'rgb(222, 221, 246)'"
                                     style="background-color: rgb(222, 221, 246)">风格五
                                </div>
                            </div>
                            <div class="setfontsize" v-if="isFontSet">
                                <div class="theme fontsize" @click="caseDetailFontSize = '14px'">小</div>
                                <div class="theme fontsize" @click="caseDetailFontSize = '16px'">中</div>
                                <div class="theme fontsize" @click="caseDetailFontSize = '19px'">大</div>
                                <div class="theme fontsize" @click="caseDetailFontSize = '22px'">更大</div>
                                <div class="theme fontsize" @click="caseDetailFontSize = '26px'">超大</div>
                            </div>
                        </div>
                    </div>
                    <div class="subject subjective-question">
                        <div v-show="caseShow" class="title-subj" ref="titleSubj"
                             :style="{height: titleHeight, maxHeight: editShow ? '500px' : 'calc(100vh - 200px)'}">
                            <div class="head-title">
                                <h6 class="title">
                                    {{
                                        questionType === 0 ? '论述题' : '案例分析题'
                                    }}，共{{ currentData.bdSubjective.totalScore }}分
                                </h6>
                                <div class="buttons">
                                    <button class="button zoom-window" @click="handleMarkQuestion">标记题干</button>
                                    <button class="button zoom-window" @click="handleMarkCancel">取消标记</button>
                                    <button class="button zoom-window"
                                            @click="editShow = !editShow; titleHeight = editShow ? '37%' : '100%';">
                                        {{ editShow ? '放大窗口' : '恢复窗口' }}
                                    </button>
                                </div>
                            </div>
                            <div class="text" :style="{fontSize: caseDetailFontSize}">
                                <div id="zgtitle" class="isedit" v-html="currentData.bdSubjective.caseDetail">
                                </div>
                            </div>
                        </div>
                        <div v-show="editShow" class="area-anwwer"
                             :style="{maxHeight: caseShow ? '55%' : 'calc(100vh - 200px)'}"
                             style="min-height: 200px; overflow-y: scroll;">
                            <div v-show="caseShow" id="tuodongbar" class="tuodong"></div>
                            <div class="small-title">
                                <ul class="quest-group">
                                    <li class="quest" v-for="(item, key) in currentData.bdSubjectiveQuestionList"
                                        :key="key"
                                        @click="selectQuestionIndex(key)" :class="{active: curNow === key}">问题{{
                                            key + 1
                                        }}
                                        {{ item.answer ? '' : '*' }}
                                    </li>
                                    <li class="txt">请在对应的答题区域内答题！</li>
                                </ul>
                                <div class="btnss">
                                    <button class="button zoom-window" @click="handleRevoke">撤销</button>
                                    <button class="button zoom-window" @click="handleRecovery">恢复</button>
                                    <button class="button zoom-window" @click="handleCopy">复制</button>
                                    <button class="button zoom-window" @click="handlePaste">粘贴</button>
                                    <button class="button zoom-window" @click="caseShow = !caseShow;">
                                        {{ caseShow ? '放大窗口' : '恢复窗口' }}
                                    </button>
                                </div>
                            </div>
                            <div class="anwwer" style="width: 100%; overflow-x: hidden">
                                <p class="text">
                                    {{ curNow + 1 }}. {{ currentQuestion.questionStem }}</p>
                                <h6 class="hint">请在下方答题区内答题。
                                    <span style="color: rgb(255, 0, 0); font-family: serif;">
                    已输入字数为：{{
                                            currentQuestion.answer ? (currentQuestion.conclusion ? currentQuestion.answer.length - 2 : currentQuestion.answer.length) : 0
                                        }}
                  </span>
                                </h6>
                                <textarea maxlength="5000" class="myanswer" v-model="currentQuestion.answer"
                                          placeholder="请输入答案" style="min-height: 150px;"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 法律法规框 -->
            <subjective-law v-show="lawShow" @close="lawShow = false"></subjective-law>
            <!--      提示框-->
            <div v-show="tipsShow" class="mock-popuup">
                <div class="dialog_main">
                    <div class="dialog_content">
                        <p class="dialog_text" v-if="status === 1">
                            提示：还有十五分钟交卷！
                        </p>
                        <p class="dialog_text" v-else>
                            <span v-if="status === 0">试卷尚有【{{ notCompleteCount }}】小问<span style="color: red;">未作答</span>。</span>
                            <br>确定继续交卷吗？
                        </p> <!---->
                        <div class="dialog_button">
                            <button type="button" class="button" @click="tipsShow = false">返回作答</button>
                            <button type="button" class="button" @click="handleSubmit">交卷</button>
                            <button type="button" class="button" style="display: none;">确定交卷</button>
                            <button type="button" class="button" style="display: none;">关闭界面</button>
                            <button type="button" class="button" style="display: none;">确定离开</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Message} from 'element-ui'
import SubjectiveLaw from "../../law/components/subjectiveLaw";

export default {
    name: "paperDetail",
    components: {SubjectiveLaw},
    data() {
        return {
            isDown: false,
            isFontSet: false,
            isStyle: false,
            isShow: true,
            tipsShow: false,
            leftShow: true,
            titleHeight: '37%',
            tempContent: '',
            copyText: '',
            caseShow: true,
            editShow: true,
            stackRevoke: [],  //撤销栈
            stackRecovery: [], // 恢复栈,
            lawShow: false,
            countTime: 0,
            interval: '',
            toLiveBtn: '00:00:00',
            status: 0, // 0：还有多少题，确定离开吗(返回作答，交卷) 1: 离考试结束仅剩15分钟(返回作答，交卷)   2:还要继续交卷吗（返回作答，交卷）
            subjectivePaper: {bdSubjectivePaper: {}},
            isShow1: true,
            isShow2: true,
            currentData: {
                bdSubjective: {
                    totalScore: 0
                }
            },
            currentQuestion: {answer: ''},
            curNow: 0,
            questionType: 0,
            tempSubjectiveKey: 0,
            isLastEnd: true,
            isNextEnd: false,
            notCompleteCount: 0,

            caseDetailFontSize: '19px',
            bgColor: 'rgb(221, 235, 246)',
            isPaper: true
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#2d6dae')
    },
    watch: {
        "currentQuestion.answer": {
            handler(val, oldVal) {
                if (this.tempContent !== val) {
                    this.stackRevoke.push(oldVal)
                }
                this.currentData.bdSubjectiveQuestionList.forEach(item => {
                    item.isComplete = item.answer;
                })
            },
            deep: true
        }
    },
    mounted() {
        if (!this.$route.query.id) {
            this.$router.push({name: 'index'})
        } else {
            // 获取单个题目
            this.$api.get('/subjective/getBankSubjective', {
                params: {
                    id: this.$route.query.id
                }
            }).then(res => {
                this.currentData = res.data
                this.currentQuestion = res.data.bdSubjectiveQuestionList[0]
                // 单个题目默认两小时
                this.countTime = 2 * 60 * 60
                // 指定当前小问
                if (this.currentData.bdSubjectiveQuestionList.length > 0) {
                    this.currentQuestion = this.currentData.bdSubjectiveQuestionList[0]
                }
                this.curNow = 0
                // 判断是否还有下个小问
                this.judgeNextEnd()
                this.subjectivePaper.bdSubjectivePaper.doTime = this.subjectivePaper.bdSubjectivePaper.doTime ? this.subjectivePaper.bdSubjectivePaper.doTime : 0
                // 倒计时事件
                this.countdown()
                // 开始计时
                this.timeStart()
            })
            this.handleDragBar()
        }
    },
    //当离开页面时，清除倒计时
    beforeDestroy() {
        clearInterval(this.interval)
        clearInterval(this.timer)
    },
    methods: {
        // 上下大小拖拽
        handleDragBar() {
            let _this = this
            let resize = document.getElementById("tuodongbar")
            resize.onmousedown = function (e) {
                let startY = e.clientY
                let orginHeight = _this.$refs.titleSubj.offsetHeight
                document.onmousemove = function (e) {
                    let endY = e.clientY
                    _this.titleHeight = (orginHeight + (endY - startY)) + 'px'
                }
                document.onmouseup = function () {
                    document.onmousemove = null;
                    document.onmouseup = null;
                    resize.releaseCapture && resize.releaseCapture();
                };
                resize.setCapture && resize.setCapture();
                return false
            }
        },
        // 交卷-判断是否还有题目没有完成
        handleJudge() {
            // 判断还有多少题没完成
            let count = 0

            this.currentData.bdSubjectiveQuestionList.forEach(item => {
                if (!item.isComplete) {
                    count += 1
                }
            })
            this.notCompleteCount = count
            if (count === 0) {
                this.status = 2
            } else {
                this.status = 0
            }
            this.tipsShow = true
        },
        // 开始计时
        timeStart() {
            let _this = this
            this.timer = setInterval(function () {
                _this.currentQuestion.studentTotalTime = _this.currentQuestion.studentTotalTime ? (_this
                    .currentQuestion.studentTotalTime + 1) : 1
            }, 1000);
        },
        judgeNextEnd() {
            // 判断是否上一问结束
            // 下一问
            this.isNextEnd = !(this.curNow < this.currentData.bdSubjectiveQuestionList.length - 1)
            this.isLastEnd = !(this.curNow > 0)
        },
        nextQuestion(isNext) {
            if (isNext) {
                // 下一个
                // 先看是论述题还是案例分析
                this.selectQuestionIndex(this.curNow + 1)
            } else {
                // 上一个
                this.selectQuestionIndex((this.curNow - 1))
            }
            this.judgeNextEnd()
        },
        // 更新下一个小问
        selectQuestionIndex(index) {
            this.currentQuestion = this.currentData.bdSubjectiveQuestionList[index]
            this.curNow = index
            this.judgeNextEnd()
        },
        // 交卷-提交单个题目
        handleSubmit() {
            let _this = this
            this.currentData.isAutoCorrect = false
            this.$api.post('/subjective/submitSubjective', this.currentData).then(() => {
                Message.success('提交成功！可以点击“查看答案”按钮看答案哦~')
                setTimeout(function () {
                    _this.$router.push({name: 'subjectiveSingleEnd', query: {id: _this.$route.query.id}})
                }, 1000);
            })
        },
        // 倒计时事件
        countdown() {
            const that = this
            that.interval = setInterval(() => {
                if (that.countTime === 0) {
                    // 计时结束，清除缓存
                    clearInterval(that.interval)
                    // 执行交卷
                    that.handleSubmit()
                } else {
                    that.countTime--
                    that.subjectivePaper.bdSubjectivePaper.doTime = (that.subjectivePaper.bdSubjectivePaper.totalTime * 60) - that.countTime
                    if (that.countTime === 15 * 60) {
                        that.status = 1
                        that.tipsShow = true
                    }
                    // let day = parseInt(that.countTime / 60 / 60 / 24)
                    let hr = parseInt(that.countTime / 60 / 60 % 24)
                    let min = parseInt(that.countTime / 60 % 60)
                    let sec = parseInt(that.countTime % 60)

                    // day = day > 9 ? day : '0' + day
                    hr = hr > 9 ? hr : '0' + hr
                    min = min > 9 ? min : '0' + min
                    sec = sec > 9 ? sec : '0' + sec
                    that.toLiveBtn = `${hr}:${min}:${sec}`

                    // 每10秒保存一次答案
                    if (this.countTime % 10 === 0) {
                        this.$api.post('/subjective/saveTempSubjectiveRecord', that.currentData).then(() => {
                            console.log('保存题目')
                        })
                    }
                }
            }, 1000);
        },
        // 取消标记题干
        handleMarkCancel() {
            this.currentData.bdSubjective.caseDetail = this.currentData.bdSubjective.caseDetail.replace(/<span (.*?)>/g, '').replace(/<\/span>/g, '')
        },
        // 标记题干
        handleMarkQuestion() {
            let caseData = this.currentData.bdSubjective.caseDetail
            let start = window.getSelection().anchorOffset > window.getSelection().focusOffset ? window.getSelection().focusOffset : window.getSelection().anchorOffset
            let end = caseData.length
            this.currentData.bdSubjective.caseDetail = caseData.substring(0, start) + caseData.substring(start, end).replace(window.getSelection().toString(), `<span style="background-color: rgb(255, 255, 0);">` + window.getSelection().toString() + `</span>`)
        },
        // 复制
        handleCopy() {
            this.copyText = window.getSelection().toString()
            // document.execCommand("Copy")
        },
        // 粘贴题干
        handlePaste() {
            this.currentQuestion.answer = (this.currentQuestion.answer ? this.currentQuestion.answer : '') + this.copyText
        },
        handleRevoke() {
            if (this.stackRevoke.length > 0 && this.currentQuestion.answer && this.currentQuestion.answer.length > 0) {
                this.stackRecovery.push(this.currentQuestion.answer)
                this.tempContent = this.stackRevoke.pop()
                this.currentQuestion.answer = this.tempContent
            }
        },
        handleRecovery() {
            if (this.stackRecovery.length > 0) {
                this.currentQuestion.answer = this.stackRecovery.pop()
            }
        },
        fontSet() {
            this.isFontSet = !this.isFontSet
            this.isStyle = false
        },
        toDown() {
            this.isDown = !this.isDown
        }
    }
}
</script>

<style scoped>
@import "~@ztree/ztree_v3/css/zTreeStyle/zTreeStyle.css";

body {
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    min-width: 960px;
    background: #2d6dae;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.nolist {
    position: absolute;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: #444;
    margin: auto;
    height: 1em;
    font-weight: 600;
    line-height: 1;
    font-size: 28px;
    font-family: SimSun;
}

.wind-body .contents .scroll-box {
    white-space: pre-wrap;
    overflow: auto;
    line-height: 1.3;
    height: 100%;
    padding: 20px;
}

.menu-border {
    position: absolute;
    height: 100%;
    width: 5px;
    top: 0;
    left: 0;
    cursor: e-resize;
}

.wind-body .contents {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 100%;
}

.ztree {
    margin: 0;
    padding: 5px;
    color: #333;
}

.menu {
    width: 100%;
    height: calc(100% - 62px);
    overflow: auto;
}

.search .next-btn {
    width: 65px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-left: 10px;
    cursor: pointer;
}

.search .next-btn .icon-left2 {
    margin-right: 10px;
}

.search .next-btn .button {
    float: left;
    width: 18px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dfdfdf;
    color: #999;
    background: #fafafa;
    cursor: pointer;
}

.search .input-parent .searchbox_button {
    position: absolute;
    right: 4px;
    top: 5px;
    cursor: pointer;
    opacity: .6;
    z-index: 1;
    width: 16px;
    height: 16px;
    background: url(../../../assets/img/searchbox_button.png) no-repeat;
}

.search .input-param {
    font-size: 14px;
    border: 0;
    padding: 0 0 0 4px;
    white-space: normal;
    vertical-align: top;
    outline-style: none;
    resize: none;
    height: 28px;
    line-height: 28px;
    border-bottom: 1px solid #666;
    width: 100%;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.search .input-parent {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 30px;
    width: 270px;
}

.catalogue .top .iconfont {
    padding: 0 10px;
    font-size: 18px;
}

.catalogue .top .title {
    padding-left: 10px;
    font-family: SimSun;
    font-size: 14px;
    color: #404040;
}

.catalogue .top {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #ddd;
    background-color: #fafafa;
}

.wind-body .catalogue {
    width: 448px;
    height: 100%;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-right: 1px solid #ddd;
    overflow: hidden;
    -webkit-transition: width .2s;
    transition: width .2s;
}

.wind-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    grid-row: 1;
    height: calc(100% - 40px);
}

.wind-head .off {
    font-size: 19px;
}

.wind-head .changewind:before {
    content: "";
    position: relative;
    z-index: 30;
    display: block;
    margin: auto;
    width: 10px;
    height: 9px;
    border: 1px solid #666;
    border-top-width: 2px;
    background: #fff;
}

.wind-head .changewind.big:after {
    display: block;
}

.wind-head .changewind:after {
    content: "";
    position: absolute;
    z-index: 28;
    display: none;
    top: 11px;
    right: 5px;
    margin: auto;
    width: 11px;
    height: 11px;
    border: 1px solid #333;
}


.wind-head .changewind {
    position: relative;
    z-index: 25;
}

.wind-head .button {
    float: left;
    width: 30px;
    height: 40px;
}

.wind-head .title {
    font-size: 14px;
    line-height: 40px;
    margin-left: 20px;
    font-family: SimSun;
    color: #404040;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-corner {
    background: #82AFFF;
}

::-webkit-scrollbar-thumb {
    background: rgba(50, 50, 50, 0.4);
    border-radius: 4px;
}

.exam-answer {
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    min-width: 960px;
    background: #2d6dae;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.center {
    z-index: 3;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-height: 450px;
}

.center, .exam-answer {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

li {
    list-style: none;
}

.subjective-question .area-anwwer .small-title .zoom-window {
    width: 60px;
    height: 21px;
    margin-left: 2px;
    color: #000;
    font-size: 12px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -16px 0;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.subjective-question .area-anwwer .anwwer .hint {
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 10px;
    font-size: 18px;
    color: #333;
}

.subjective-question .area-anwwer .anwwer .text {
    font-size: 17px;
    white-space: pre-wrap;
    line-height: 1.5;
    letter-spacing: 1px;
    margin-bottom: 5px;
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
}

.subjective-question .area-anwwer .anwwer {
    height: 100px;
    -webkit-box-flex: 1;
    flex: 1;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    overflow: auto;
}

.subjective-question .area-anwwer .small-title .btnss {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.subjective-question .area-anwwer .small-title .quest.active {
    background: #fb0;
}

.leftnav .subjcard .subitem .a.active {
    color: #a935da !important;
}

.subjective-question .area-anwwer .small-title .quest {
    float: left;
    width: 60px;
    margin-right: 6px;
    margin-bottom: 6px;
    font-size: 12px;
    font-weight: 700;
    line-height: 29px;
    text-align: center;
    background: #f5f5f5;
    cursor: pointer;
}

.subjective-question .area-anwwer .small-title .quest-group {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding-top: 5px;
    padding-right: 10px;
    overflow: hidden;
}

.subjective-question .area-anwwer .small-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    margin-bottom: 5px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-bottom: 1px solid #a1b5fa;
}

.subjective-question .area-anwwer .tuodong {
    height: 6px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    background: #a1b5fa;
    cursor: n-resize;
}

.subjective-question .area-anwwer {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100px;
    min-height: 100px;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}

.subjective-question .title-subj .text .unedit {
    white-space: pre-wrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
}

.subjective-question .title-subj .text {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    height: 100px;
    overflow: auto;
    background: #fff;
    padding: 10px;
    line-height: 1.2;
    letter-spacing: -.2px;
    color: #000;
}

.subjective-question .title-subj .head-title .buttons .zoom-window {
    width: 58px;
    height: 21px;
    margin-left: 5px;
    color: #000;
    font-size: 12px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -16px 0;
}

.subjective-question .title-subj .head-title .buttons {
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.subjective-question .title-subj .head-title .title {
    float: left;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.subjective-question .title-subj .head-title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 40px;
    line-height: 40px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-bottom: 5px;
    border-bottom: 1px solid #a1b5fa;
}

.subjective-question .title-subj {
    height: 40%;
    min-height: 200px;
    max-height: 500px;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.subject {
    height: 100%;
    padding: 0 10px 5px 0;
    overflow: auto;
}

.subjective-question {
    height: 100%;
}

.subjective-question .title-subj, .subjective-question {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.paperTitle .theme {
    font-size: 12px;
    line-height: 12px;
    margin-left: 6px;
    cursor: pointer;
    height: 12px;
    padding: 4px;
    margin-top: 4px;
    float: left;
    -webkit-box-sizing: initial;
    box-sizing: initial;
}

button {
    outline: none;
    -webkit-appearance: none;
    cursor: pointer;
}

.paperTitle .themeLi .setfontsize, .paperTitle .themeLi .setStyle {
    float: left;
}

.paperTitle .li .mark, .paperTitle .li .themeP, .paperTitle .li .unmark {
    width: 58px;
    height: 21px;
    float: left;
    margin: 4px 2px auto 0;
    color: #000;
    font-size: 12px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -16px 0;
}

.paperTitle .single {
    float: left;
    height: 29px;
    margin-right: 6px;
    width: 109px;
    font-size: 14px;
    font-weight: bolder;
    line-height: 29px;
    text-align: center;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -212px -33px;
}

.paperTitle .li {
    float: left;
    display: block;
    cursor: pointer;
}

.paperTitle {
    position: absolute;
    left: 22px;
    top: 10px;
    right: 0;
    z-index: 40;
    height: 29px;
    background: url(../../../assets/img/titlebg.jpg) repeat-x;
}

.center .content .pack-up .pack-up-btn {
    margin-top: 200px;
    width: 10px;
    height: 62px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -16px -38px;
    cursor: pointer;
}

.center .content .pack-up {
    position: absolute;
    left: 0;
    top: 0;
    width: 11px;
    height: 100%;
    border-right: 1px solid #acc4f0;
}

.center .content {
    -webkit-box-flex: 4;
    -ms-flex: 4;
    flex: 4;
    position: relative;
    padding-top: 40px;
    padding-left: 22px;
    padding-bottom: 50px;
    height: 100%;
}

.button {
    background: transparent;
    border: none;
    line-height: 1.1;
    outline: none !important;
    -webkit-appearance: none !important;
    user-select: none;
    font-size: 17px;
    padding: 0;
}

.options-bar .button {
    width: 85px;
    height: 31px;
    margin: 0 10px;
    text-align: center;
    cursor: pointer;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -121px -33px;
}

.options-bar .topicnub {
    margin: 0 10px;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

.options-bar .testflag {
    float: left;
    margin-top: 8px;
    margin-right: 5px;
}

.leftnav {
    width: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 100%;
    border-right: 1px solid #acc4f0;
    overflow: auto;
}

.leftnav.show .options-bar {
    padding-left: 200px;
}

.options-bar {
    position: absolute;
    width: 80%;
    min-width: 960px;
    height: 40px;
    bottom: 8px;
    left: 0;
    z-index: 40;
    font-size: 18px;
    line-height: 28px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.leftnav .fagui {
    width: 109px;
    height: 29px;
    text-align: center;
    line-height: 29px;
    font-size: 14px;
    margin: 15px auto;
    cursor: pointer;
    background: url(../../../assets/img/mkbgicon.png) -212px -33px;
}

.leftnav .submark {
    text-align: left;
    margin-left: 4px;
    padding-top: 5px;
    font-size: 18px;
    color: red;
    line-height: 24px;
}

.leftnav .subjcard .subitem .a:after {
    content: attr(data-type);
    display: inline;
    color: red;
}

.leftnav .subjcard .subitem {
    overflow: hidden;
}

.leftnav .subjcard .card-top.active:after {
    background-position: -530px -51px;
}

.leftnav .subjcard .subitem .a {
    display: block;
    min-width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid #fff;
    float: left;
    color: #00f;
    cursor: pointer;
    font-family: Microsoft YaHei;
    text-decoration: underline;
}

.leftnav .subjcard .card-top:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 34px;
    height: 12px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -530px -35px;
}

.leftnav .subjcard .card-top {
    position: relative;
    padding-left: 10px;
    font-size: 14px;
    height: 28px;
    color: #000;
    text-align: left;
    font-weight: 600;
    line-height: 28px;
    background: url(../../../assets/img/left_menu_bg.jpg) repeat-x;
    cursor: pointer;
}

.leftnav .topic-card {
    display: none;
    width: 100%;
    height: 100%;
}

.leftnav.show .topic-card {
    display: block;
}

.leftnav.show {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 409px;
    min-width: 340px;
}

.hidetop {
    position: absolute;
    z-index: 9999;
    top: 6px;
    right: 2px;
    width: 92px;
    height: 26px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -427px -36px;
    cursor: pointer;
}

.down {
    background-position: -327px -37px;
}

.mockinfo .submit .submit-btn {
    width: 80px;
    height: 27px;
    background: url(../../../assets/img/submit.jpg) repeat-x;
    border: 1px solid #c60;
    color: #000;
    font-size: 13px;
    cursor: pointer;
    border-radius: 0;
}

.mockinfo .submit .time {
    margin-right: 30px;
    color: #ff0;
}

.mockinfo .submit {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    right: 0;
    top: 0;
    height: 100%;
    width: 450px;
    padding-right: 20px;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

b, strong, h1, h2, h3, h4, h5, h6 {
    font-weight: bold;
}

.mockinfo .paperinfo .title1 {
    margin-bottom: 1em;
}

.mockinfo .paperinfo {
    padding: 18px 0;
    text-align: center;
    line-height: 25px;
    color: #fff;
}

p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin: 0;
}

.mockinfo .userinfo .other {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    font-weight: 600;
    padding: 7px 0;
    color: #fff;
}

.mockinfo .userinfo .userhead {
    display: block;
    float: left;
    width: 74px;
    height: 96px;
    max-height: 100%;
    margin-right: 10px;
    -o-object-fit: contain;
    object-fit: contain;
}

.mockinfo .userinfo {
    position: absolute;
    padding: 2px 10px;
    left: 0;
    top: 0;
    height: 100%;
    width: 400px;
}

.mockinfo {
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    height: 100px;
    background: url(../../../assets/img/pic_02.png) repeat-x;
}

.mockhead {
    position: relative;
}

.subjective-question .area-anwwer .small-title .txt {
    line-height: 28px;
    float: left;
    font-weight: 700;
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
}

.subjective-question .area-anwwer .anwwer .myanswer {
    display: block;
    -webkit-box-flex: 1;
    padding: 6px 10px;
    flex: 1;
    width: 100%;
    max-height: 78%;
    overflow: auto;
    border: none;
    resize: none;
    outline: none;
}

.subjective-question .title-subj .text .isedit {
    margin-bottom: 1em;
    white-space: pre-wrap;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    font-family: SimSun, NSimSun, FangSong, \\5B8B\4F53;
}

.mock-popuup .dialog_content .dialog_button .button {
    width: 84px;
    height: 31px;
    color: #fff;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    background: url(../../../assets/img/mkbgicon.png) no-repeat -33px -33px;
}

.mock-popuup .dialog_content .dialog_button {
    position: absolute;
    right: 20px;
    bottom: 25px;
    padding-left: 40px;
}

.mock-popuup .dialog_content .dialog_text {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 210px;
    margin-top: 10px;
}

.mock-popuup .dialog_content {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 291px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.mock-popuup .dialog_main {
    position: absolute;
    z-index: 55;
    left: 50%;
    top: 42%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    min-height: 291px;
    width: 526px;
    text-align: left;
    background-color: #e4f2fb;
    background-image: url(../../../assets/img/ts_d2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 12px;
}

.mock-popuup {
    position: fixed;
    z-index: 50;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: hsla(0, 0%, 50%, .8);
}


.center .content .pack-up .pack-up-btn.open {
    background-position: 0 -36px;
}

.wind-body .contents .scroll-box .text {
    font-family: SimSun;
    margin-bottom: 1em;
    color: #404040;
    font-size: 16px;
}

</style>